import { useCallback, useEffect, useMemo, useState } from 'react';

import throttle from 'lodash/throttle';

interface IWindowSize {
  width: number;
  height: number;
}

interface IUseViewportSizing {
  windowSize: IWindowSize;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isNormalDesktop: boolean;
  isMediumDesktop: boolean;
}

export default function useViewportSizing(): IUseViewportSizing {
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: 0,
    height: 0,
  });

  const isMobile = useMemo(() => windowSize.width < 768, [windowSize.width]);
  const isTablet = useMemo(() => windowSize.width >= 768 && windowSize.width < 992, [windowSize.width]);
  const isDesktop = useMemo(() => windowSize.width >= 992, [windowSize.width]);
  const isNormalDesktop = useMemo(() => windowSize.width >= 1200, [windowSize.width]);
  const isMediumDesktop = useMemo(() => windowSize.width >= 1200 && windowSize.width < 1400, [windowSize.width]);

  // eslint-disable-next-line
  const handleResize = useCallback(
    throttle(
      () =>
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        }),
      200,
    ),
    [],
  );

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return {
    windowSize,
    isMobile,
    isTablet,
    isDesktop,
    isNormalDesktop,
    isMediumDesktop,
  };
}
