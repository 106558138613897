import './RightContent.scss';

interface IProps {
  children: React.ReactNode;
}

const RightContent = ({ children }: IProps) => {
  return <div className='page-right-content'>{children}</div>;
};

export default RightContent;
