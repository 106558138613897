/* eslint-disable max-len */
import { SUPPORT_EMAIL } from '@constants/constants';
import { ToastItem, ToastType } from '@customTypes/toast';

export const DEFAULT_ERROR_CONTENT = `Something went wrong. Please try again, or return later. If the problem recurs, contact ${SUPPORT_EMAIL}.`;

export const BLOCKED_BY_BROWSER_CONTENT = `An action was blocked by your browser. Check your browser settings, try another browser, or contact ${SUPPORT_EMAIL} for assistance.`;

export const ERROR_TOAST_BY_CODE: Record<number, ToastItem> = {
  408: {
    type: ToastType.CUSTOM_ACTIONS,
    errorString: '408: Request Timeout',
  },
  500: {
    errorString: '500: Internal Server Error',
  },
  503: {
    errorString: '503: Service Unavailable (server busy)',
  },
};
