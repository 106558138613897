export interface IUsername {
  first: string;
  last: string;
  middle?: string;
  credentials?: string;
}

export interface IDemographics {
  sex: 'MALE' | 'FEMALE';
  dob: string;
}

export interface IAddress {
  city: string;
  postal_code: string;
  region: string;
  street_address: string;
  street_address_2?: string;
}

export interface IContact {
  phone: string;
  address: IAddress;
}

export enum UserRole {
  ORDERING_PROVIDER = 'ORDERING_PROVIDER',
  GENETIC_COUNSELOR = 'GENETIC_COUNSELOR',
  CLINICAL_STAFF_MEMBER = 'CLINICAL_STAFF_MEMBER',
  CLINICAL_RESEARCHER = 'CLINICAL_RESEARCHER',
  LAB_MANAGER = 'LAB_MANAGER',
  OTHER = 'OTHER',
}

export enum HasTeamMemberOption {
  NO = 'No',
  YES = 'Yes',
}

export interface IProfile {
  name: IUsername;
  role: UserRole;
  npi?: string;
  email: string;
  phone?: string;
  fax?: string;
}

export interface ITeamMember extends IProfile {
  id?: string;
}

export interface IOrganization {
  name: string;
  phone: string;
  fax?: string;
  address: IAddress;
}

export interface ISignupForm {
  signup_code?: string;
  provider: IProfile;
  has_teammates: boolean;
  teammates: ITeamMember[];
  organization: IOrganization;
}
