import { useEffect } from 'react';

import { FieldArray, useFormikContext } from 'formik';
import { startCase, kebabCase } from 'lodash';
import { Alert, Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faUserPlus } from '@fortawesome/pro-duotone-svg-icons';

import FormField from '@components/Form/FormField';
import { ROLE_PLACEHOLDER, SUPPORT_EMAIL } from '@constants/constants';
import { HasTeamMemberOption, UserRole } from '@customTypes/user';
import type { ISignupForm } from '@customTypes/user';
import useEffectExceptMount from '@hooks/useEffectExceptMount';

import AdditionalAccountFieldset from '../AdditionalAccountFieldset/AdditionalAccountFieldset';

import './SignupForm.scss';

const PROVIDER_PREFIX = 'provider';
const ORG_PREFIX = 'organization';
// this is just a way to quickly mock additional account display
const supportEmail = `mailto:${SUPPORT_EMAIL}?subject=Bulk Registration Request`;
const EMPTY_TEAMMATE = {
  name: {
    first: '',
    last: '',
  },
  role: '' as UserRole,
  email: '',
};

export default function SignupForm() {
  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    handleBlur,
    submitCount,
    isValid,
    isSubmitting,
    handleSubmit,
    setFieldTouched,
  } = useFormikContext<ISignupForm>();

  useEffect(() => {
    if (!values.has_teammates) {
      setFieldValue('teammates', []);
    } else {
      setFieldValue('teammates', [{ ...EMPTY_TEAMMATE, id: Date.now() + Math.random() }]);
    }
  }, [values.has_teammates, setFieldValue]);

  useEffectExceptMount(() => {
    if (values.provider.role === UserRole.ORDERING_PROVIDER && !touched.provider?.npi) {
      setFieldTouched('provider.npi', true);
    }
  }, [values.provider.role, touched.provider?.npi, setFieldTouched]);

  return (
    <Form className='d-flex flex-column gap-4 mt-3' noValidate onSubmit={handleSubmit}>
      <Row>
        <FormField
          name='signup_code'
          className='col-12 col-md-6 col-lg-4'
          label={
            <>
              Sign-up code <small className='fst-italic'>(optional)</small>
            </>
          }
          helperText='Connect to early access products or partnerships'
        />
      </Row>

      <fieldset id='fset_Profile' className='fset' aria-describedby='fset_ProfileDesc'>
        <legend className='legend'>Your profile</legend>
        <Stack className='gap-12 px-3'>
          <p id='fset_ProfileDesc' className='text-body-secondary mb-0'>
            Set up your account profile
          </p>
          <Row>
            <FormField cols={4} prefix={PROVIDER_PREFIX} name='name.first' label='First name' required />
            <FormField cols={4} prefix={PROVIDER_PREFIX} name='name.middle' label='Middle name or initial' />
            <FormField cols={4} prefix={PROVIDER_PREFIX} name='name.last' label='Last name' required />
          </Row>
          <Row>
            {/* begin role/npi */}
            <Form.Group as={Col} controlId='select_ProfileRole' className='col-12 col-lg-4'>
              <Form.Label className='required'>Role</Form.Label>
              <Form.Select
                name={`${PROVIDER_PREFIX}.role`}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.provider?.role && !!errors.provider?.role}
                required
              >
                <option value={ROLE_PLACEHOLDER} disabled={!!values.provider.role}>
                  {ROLE_PLACEHOLDER}
                </option>
                {Object.values(UserRole).map((opt, index) => (
                  <option key={'select_ProfileRole_opt' + index} value={opt}>
                    {startCase(kebabCase(opt))}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback as='small' role='alert' type='invalid'>
                Role selection is required.
              </Form.Control.Feedback>
            </Form.Group>

            <FormField
              cols={4}
              prefix={PROVIDER_PREFIX}
              name='npi'
              required={values.provider.role === UserRole.ORDERING_PROVIDER}
              label='NPI'
              helperText={
                values.provider.role === UserRole.ORDERING_PROVIDER
                  ? 'NPI required for selected role'
                  : !values.provider.role
                    ? 'Select a role before adding NPI'
                    : 'NPI is optional for selected role'
              }
              disabled={!values.provider.role}
            />
            {/* end role/npi */}
            <FormField
              cols={4}
              prefix={PROVIDER_PREFIX}
              name='name.credentials'
              label='Credentials'
              helperText='e.g. MD, CNP'
            />
          </Row>
          <Row>
            <FormField cols={4} prefix={PROVIDER_PREFIX} name='email' type='email' required />
            <FormField cols={4} prefix={PROVIDER_PREFIX} name='phone' type='tel' helperText='e.g. +18318675309' />
            <FormField cols={4} prefix={PROVIDER_PREFIX} name='fax' type='tel' helperText='e.g. +18318675309' />
          </Row>
        </Stack>
      </fieldset>

      <fieldset id='fset_Teammates'>
        <legend className='legend'>Your teammates</legend>
        <fieldset id='fset_TeammatesRadio' className='px-3'>
          <legend className='legend-label mb-1'>
            While you are here would you like to set up accounts for your teammates?
          </legend>
          <Stack role='radiogroup' className='gap-12' aria-labelledby='teammates_RadioGroupDesc'>
            <p id='teammates_RadioGroupDesc' className='text-body-secondary mb-0'>
              To register more than 10 accounts please contact <a href={supportEmail}>support@myome.com</a>.
              <span className='visually-hidden'>
                Select yes to add additional accounts. Select no to remove all additional accounts.
              </span>
            </p>
            <Stack gap={3} className='px-2'>
              {Object.values(HasTeamMemberOption).map((rVal, index) => (
                <Form.Check
                  key={'radio_' + index}
                  type='radio'
                  id={'radio_Teammates_' + index}
                  name='has_teammates'
                  label={rVal}
                  value={rVal}
                  defaultChecked={rVal === HasTeamMemberOption.NO}
                  onChange={() => setFieldValue('has_teammates', rVal === HasTeamMemberOption.YES)}
                />
              ))}
            </Stack>
          </Stack>
        </fieldset>
      </fieldset>

      {values.has_teammates && (
        <fieldset id='fset_AdditionalAccounts' className='px-3'>
          <legend className='visually-hidden'>Additional Accounts</legend>
          <Stack className='gap-12'>
            <FieldArray name='teammates'>
              {arrayHelpers => (
                <>
                  {(values.teammates ?? []).map((account, index) => (
                    <AdditionalAccountFieldset
                      remove={() => arrayHelpers.remove(index)}
                      fsetIndex={index}
                      key={'fset_AdditionalAccount_' + account.id}
                    />
                  ))}

                  <div className='pt-3'>
                    <Button
                      id='button_AddAccount'
                      variant='outline-primary'
                      disabled={(values.teammates ?? []).length >= 10}
                      onClick={() => arrayHelpers.push({ ...EMPTY_TEAMMATE, id: Date.now() + Math.random() })}
                    >
                      <FontAwesomeIcon icon={faUserPlus as IconDefinition} fontSize={16} className='me-2' />
                      Add account
                    </Button>
                  </div>
                </>
              )}
            </FieldArray>
          </Stack>
        </fieldset>
      )}

      <fieldset id='fset_Organization' className='fset'>
        <legend className='legend'>Your organization</legend>
        <Stack className='gap-12 px-3'>
          <Row>
            <FormField
              cols={8}
              prefix={ORG_PREFIX}
              name='name'
              label='Name'
              helperText='The name of your clinic, organization, etc'
              required
            />
          </Row>
          <Row>
            <FormField
              cols={4}
              prefix={ORG_PREFIX}
              name='phone'
              type='tel'
              label='Phone'
              helperText='e.g. +18318675309'
              required
            />
            <FormField cols={4} prefix={ORG_PREFIX} name='fax' type='tel' label='Fax' helperText='e.g. +18318675309' />
          </Row>
          {/* below will need google maps api */}
          <fieldset id='fset_OrgAddress' className='nested-fset' aria-describedby='fset_OrgAddressDesc'>
            <legend className='legend'>Address</legend>
            <Stack className='gap-12 px-md-3'>
              <p id='fset_OrgAddressDesc' className='text-body-secondary mb-0'>
                The street address will populate other fields via Google Maps autocomplete
              </p>
              <Row>
                <FormField
                  cols={8}
                  label='Street Address'
                  prefix={ORG_PREFIX}
                  name='address.street_address'
                  helperText='Number and street'
                  address
                  required
                />
              </Row>
              <Row>
                <FormField cols={8} prefix={ORG_PREFIX} name='address.street_address_2' label='Address line 2' />
              </Row>
              <Row>
                <FormField cols={4} prefix={ORG_PREFIX} name='address.city' label='City' required />
                <FormField
                  cols={4}
                  prefix={ORG_PREFIX}
                  name='address.region'
                  label='Region'
                  helperText='State, province, or other country-specific region'
                  required
                />
                <FormField
                  cols={4}
                  prefix={ORG_PREFIX}
                  name='address.postal_code'
                  label='Postal code'
                  helperText='Country-specific code for geographic districts'
                  required
                />
              </Row>
            </Stack>
          </fieldset>
        </Stack>
      </fieldset>

      <div className='signup-form-bottom'>
        <Button type='submit' variant='primary' disabled={isSubmitting}>
          Submit for account approval
        </Button>
      </div>

      {!isValid && submitCount > 0 && (
        <Alert show variant='danger' className='mb-0 mt-n2'>
          Required field(s) missing. Please review form.
        </Alert>
      )}
    </Form>
  );
}
