import { ReactNode } from 'react';

import { Stack } from 'react-bootstrap';

import { SUPPORT_EMAIL } from '@constants/constants';

export interface ErrorMessageByCode {
  code: number;
  errorString: string;
  title: string;
  subtitle: string | ReactNode;
}

const serverErrorTitle = 'Something went wrong.';
const serverErrorSubtitle = (
  <Stack>
    <span>We could not complete your request.</span>
    <span>Please try again. If the problem recurs, contact</span>
    <span>
      <b>{SUPPORT_EMAIL}</b>
    </span>
    <span>We&apos;re sorry for the inconvenience.</span>
  </Stack>
);

export const ERROR_MESSAGES_BY_CODE: ErrorMessageByCode[] = [
  {
    code: 401,
    errorString: 'Not Authorized',
    title: 'Account Error',
    subtitle: (
      <Stack>
        <span>Your account is not setup for access to the MyOme Provider Portal.</span>
        <span>
          Please contact <b>{SUPPORT_EMAIL}.</b>
        </span>
        <span>We&apos;re sorry for the inconvenience.</span>
      </Stack>
    ),
  },
  {
    code: 403,
    errorString: 'Forbidden',
    title: 'Nope',
    subtitle: 'Your request is forbidden.',
  },
  {
    code: 404,
    errorString: 'Not Found',
    title: "Sorry we can't find that page.",
    subtitle: 'It appears the page you are looking for does not exist.',
  },
  {
    code: 502,
    errorString: 'Bad Gateway',
    title: serverErrorTitle,
    subtitle: serverErrorSubtitle,
  },
  {
    code: 503,
    errorString: 'Service Unavailable',
    title: serverErrorTitle,
    subtitle: serverErrorSubtitle,
  },
  {
    code: 504,
    errorString: 'Gateway Timeout',
    title: serverErrorTitle,
    subtitle: serverErrorSubtitle,
  },
];

export const DEFAULT_ERROR_MESSAGE: ErrorMessageByCode = ERROR_MESSAGES_BY_CODE[2];
