import { useMemo, useRef, useState } from 'react';

import { CustomFloatingFilterProps } from 'ag-grid-react';
import { Button, Card, FormCheck, Overlay, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { STATUS_ICON_MAPPING } from '@constants/constants';
import { ResultsType } from '@customTypes/app';

import './FloatingFilter.scss';

const FloatingFilter = ({ model, onModelChange, column }: CustomFloatingFilterProps) => {
  const floatingFilterRef = useRef(null);
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(model?.filter);
  const isResultColumn = useMemo(() => column.getColId() === 'result', [column]);
  const isStatusColumn = useMemo(() => column.getColId() === 'status', [column]);
  const options = useMemo(() => {
    if (isResultColumn) {
      return Object.values(ResultsType);
    }
    if (isStatusColumn) {
      return ['Awaiting Sample', 'Analyzing', 'Reported', 'Canceled', 'Failed'];
    }
    return [];
  }, [isResultColumn, isStatusColumn]);

  const onFilterApply = () => {
    onModelChange({
      type: 'equals',
      filter: selectedOption,
    });
    setShow(false);
  };

  return (
    <div className='custom-floating-filter' ref={floatingFilterRef}>
      <div className='d-flex justify-content-center floating-trigger'>
        <Button
          data-testid={`floating-trigger-${column.getColId()}`}
          variant='link'
          className='floating-trigger-btn'
          onClick={() => setShow(true)}
        >
          <span className='ag-icon ag-icon-filter' role='presentation'></span>
        </Button>
      </div>

      <Overlay target={floatingFilterRef.current} show={show} placement='auto' rootClose onHide={() => setShow(false)}>
        {props => (
          <Card {...props} className='ag-theme-quartz ag-popup-child ag-menu floating-filter-card'>
            <Card.Body>
              <Stack className='gap-2 filter-body'>
                <p className='fw-bold mb-0'>Filter {isResultColumn ? 'Results' : 'Status'}</p>

                <Stack>
                  <FormCheck
                    type='radio'
                    name='filterGroup'
                    label='All'
                    id='filter-option-all'
                    checked={!selectedOption}
                    autoFocus={!selectedOption}
                    onChange={() => setSelectedOption(null)}
                  />
                  {options.map(option => (
                    <FormCheck
                      key={option}
                      type='radio'
                      name='filterGroup'
                      label={option}
                      id={`filter-option-${option}`}
                      value={option}
                      checked={selectedOption === option}
                      autoFocus={selectedOption === option}
                      onChange={event => setSelectedOption(event.target.value)}
                    />
                  ))}
                </Stack>

                <div className='d-flex justify-content-end'>
                  <Button size='sm' onClick={onFilterApply}>
                    Apply
                  </Button>
                </div>
              </Stack>

              {isResultColumn && (
                <Stack className='filter-footer gap-2' as='ul' data-testid='resultFilterFooter'>
                  {STATUS_ICON_MAPPING.map(item => (
                    <li key={item.status}>
                      <FontAwesomeIcon aria-label={item.status} icon={item.icon} className='me-2' fontSize={16} />
                      {item.status}
                    </li>
                  ))}
                </Stack>
              )}
            </Card.Body>
          </Card>
        )}
      </Overlay>
    </div>
  );
};

export default FloatingFilter;
