import React from 'react';

import './LeftContent.scss';

interface IProps {
  children: React.ReactNode;
}

const LeftContent = ({ children }: IProps) => {
  return <div className='page-left-content'>{children}</div>;
};

export default LeftContent;
