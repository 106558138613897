import { useState } from 'react';

import FileSaver from 'file-saver';

import { Order, OrderResult } from '@customTypes/order';
import { Requisition } from '@customTypes/requisition';
import { getOrderResultsById } from '@store/actions';

interface UseDownloadResult {
  downloadError: boolean | string;
  setDownloadError: React.Dispatch<React.SetStateAction<boolean | string>>;
  onDownload: (order: Order, requisitionId: string) => void;
  onClinicalSummaryLetterDownload: (requisition: Requisition) => void;
}

export const useDownload = (): UseDownloadResult => {
  const [downloadError, setDownloadError] = useState<boolean | string>(false);

  const onDownload = async (order: Order, requisitionId: string) => {
    try {
      const results = await getOrderResultsById(order.order_id);

      if (results.length === 0) {
        setDownloadError(true);
      } else {
        results.forEach((orderResult: OrderResult) => {
          if (orderResult.mimetype === 'application/pdf') {
            fetch(orderResult.uri)
              .then(res => res.blob())
              .then(blob =>
                FileSaver.saveAs(blob, requisitionId + ' - ' + order.product_name.replace(/,/g, '') + '.pdf'),
              );
          }
        });
      }
    } catch (err) {
      setDownloadError(err?.toString() ?? true);
    }
  };

  const onClinicalSummaryLetterDownload = (requisition: Requisition) => {
    try {
      if (requisition.clinical_summary_letter) {
        fetch(requisition.clinical_summary_letter.uri)
          .then(res => res.blob())
          .then(blob => FileSaver.saveAs(blob, `${requisition.requisition_id} - Clinical Summary Letter.pdf`));
      }
    } catch (err) {
      setDownloadError(err?.toString() ?? true);
    }
  };

  return {
    onDownload,
    downloadError,
    setDownloadError,
    onClinicalSummaryLetterDownload,
  };
};
