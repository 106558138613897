import { useMemo } from 'react';

import { Clinician } from '@customTypes/clinician';
import { getClinicianName } from '@helpers/clinician';

export const useClinician = (clinician?: Clinician | null) => {
  const clinicianName = useMemo(() => getClinicianName(clinician), [clinician]);

  return {
    clinicianName,
  };
};
