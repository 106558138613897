import { createContext, Dispatch, useReducer } from 'react';

import { AppActions, AppState } from '@customTypes/app';
import Reducer from '@store/reducer';

interface StoreProps {
  children: React.ReactNode | React.ReactNode[];
  testDispatch?: React.DispatchWithoutAction;
  testState?: AppState;
}

export const Context = createContext<[AppState, Dispatch<AppActions>]>([{} as AppState, () => undefined]);

export const initialState: AppState = {
  isFetchingClinician: false,
  requisitions: undefined,
  formattedProductsList: [],
  patientsMap: {},
};

export const Store: React.FC<StoreProps> = ({ children, testDispatch, testState }) => {
  const [iState, iDispatch] = useReducer(Reducer, initialState);
  const dispatch = testDispatch || iDispatch;
  const state = testState || iState;

  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export default Store;
