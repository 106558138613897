import { SpinnerDotted } from 'spinners-react';

const LoadingIndicator = () => {
  return (
    <div role='spinner' style={{ color: '#003595', fontSize: '24px', fontWeight: '600', marginTop: '18px' }}>
      <SpinnerDotted color='#003595' />
    </div>
  );
};

export default LoadingIndicator;
