import { useEffect, useRef } from 'react';

const useEffectExceptMount: typeof useEffect = (effect, deps) => {
  const didRunOnMount = useRef<boolean>(false);

  useEffect(() => {
    if (!didRunOnMount.current) {
      didRunOnMount.current = true;
      return;
    }

    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useEffectExceptMount;
