import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import { CellKeyDownEvent, ColDef, GridApi, ColumnState, ITooltipParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { Clinician } from '@customTypes/clinician';
import { Requisition } from '@customTypes/requisition';
import { IUsername } from '@customTypes/user';
import { dateColumnComparator } from '@helpers/agGrid';
import { getClinicianName } from '@helpers/clinician';
import { getIconByResult } from '@helpers/common';
import { isOrderReported } from '@helpers/order';
import { getRequisitionResult, getRequisitionStatus } from '@helpers/requisition';
import useBreakpoint, { SIZE_SM, SIZE_XS } from '@hooks/useBreakpoint';

import FloatingFilter from '../FloatingFilter/FloatingFilter';
import RequisitionsStatusPanel from './RequisitionsStatusPanel';
import RequisitionDownloadMenu from '../RequisitionDownloadMenu/RequisitionDownloadMenu';
import './RequisitionTable.scss';

interface RequisitionTableProps {
  data: Requisition[];
  showPatientName?: boolean;
  loading: boolean;
  loadingError: string | null;
  onRetry: () => void;
}

interface ExtendedRequisition extends Requisition {
  status: string | null;
}

const RequisitionTable: React.FC<RequisitionTableProps> = ({
  data,
  loading,
  loadingError,
  showPatientName = true,
  onRetry,
}) => {
  const breakpoint = useBreakpoint();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<ExtendedRequisition[]>([]);
  const [sortModel, setSortModel] = useState<ColumnState[]>([{ colId: 'submitted_at', sort: 'desc', sortIndex: 0 }]);
  const [gridApi, setGridApi] = useState<GridApi<ExtendedRequisition> | null>(null);

  useEffect(() => {
    const extendedData = data.map(requisition => ({
      ...requisition,
      status: getRequisitionStatus(requisition),
    }));

    setTableData(extendedData);
  }, [data]);

  const onPatientNameAction = useCallback(
    (evt: MouseEvent, requisition: Requisition) => {
      evt.preventDefault();
      navigate(`/patients/${requisition.individual_id}`);
    },
    [navigate],
  );

  const displayNameByHideState = useCallback(
    (name: IUsername) => {
      if (!showPatientName) {
        return (
          <span style={{ color: 'transparent', textShadow: '0 0 8px #000', overflow: 'hidden' }}>
            {'#'.repeat(name.first.length + name.last.length)}
          </span>
        );
      } else {
        return <span>{name.first + ' ' + name.last}</span>;
      }
    },
    [showPatientName],
  );

  const columns = useMemo(() => {
    const cols = [
      {
        headerName: '',
        field: 'download',
        headerComponentParams: {
          template: '<span>&nbsp;</span>',
        },
        headerClass: 'download-menu-col-header',
        width: 34,
        pinned: 'left',
        filter: false,
        sortable: false,
        resizable: false,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellClass: 'download-menu-cell',
        tooltipValueGetter: ({ data: requisition }: ITooltipParams<Requisition>) => {
          const orders = requisition?.orders?.filter(order => isOrderReported(order));

          return orders?.length && requisition?.clinical_summary_letter ? '' : 'No downloads available';
        },
        cellRenderer: ({ data: requisition }: { data: Requisition }) => {
          return <RequisitionDownloadMenu requisition={requisition} />;
        },
      },
      {
        headerName: 'Patient',
        field: 'name',
        width: breakpoint === SIZE_XS ? 140 : breakpoint === SIZE_SM ? 180 : 220,
        pinned: 'left',
        suppressMovable: true,
        valueGetter: ({ data: requisition }: { data: Requisition }) => {
          return displayNameByHideState(requisition.name);
        },
        cellRenderer: ({ value, data: requisition }: { value: React.ReactNode; data: Requisition }) => {
          return (
            <Button
              as='a'
              variant='link'
              size='sm'
              onClick={evt => onPatientNameAction(evt, requisition)}
              data-testid={`patient-name-${requisition.requisition_id}`}
            >
              {value}
            </Button>
          );
        },
        comparator: (value1, value2) => {
          return value1?.props?.children === value2?.props?.children
            ? 0
            : value1?.props?.children > value2?.props?.children
              ? 1
              : -1;
        },
        filterValueGetter: ({ data: requisition }: { data: Requisition }) => {
          return `${requisition.name.first} ${requisition.name.last}`;
        },
      },
      {
        headerName: 'Order ID',
        field: 'requisition_id',
        cellRenderer: ({ data: requisition }: { data: Requisition }) => {
          return (
            <Button
              as='a'
              variant='link'
              size='sm'
              onClick={evt => onPatientNameAction(evt, requisition)}
              className='requisition-id'
            >
              {requisition.requisition_id}
            </Button>
          );
        },
      },
      {
        headerName: 'Status',
        field: 'status',
        sortable: false,
        width: 150,
        floatingFilterComponent: FloatingFilter,
      },
      {
        headerName: 'Results',
        field: 'result',
        sortable: false,
        width: 90,
        cellClass: 'results-cell',
        floatingFilterComponent: FloatingFilter,
        valueGetter: ({ data: requisition }: { data: Requisition }) => {
          return getRequisitionResult(requisition);
        },
        cellRenderer: (props: CustomCellRendererProps) => {
          const result = props.value;
          const icon = getIconByResult(result);

          if (icon) {
            return (
              <FontAwesomeIcon
                aria-label={result}
                icon={icon as IconDefinition}
                color='primary text-center'
                fontSize={16}
              />
            );
          }
          return <div aria-label={result}></div>;
        },
      },
      {
        headerName: 'Order Date',
        field: 'submitted_at',
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }: { value: string }) => {
          return dayjs.utc(value).format('MM/DD/YYYY');
        },
        filterParams: {
          comparator: dateColumnComparator,
        },
      },
      {
        headerName: 'External ID',
        field: 'external_mrn',
      },
      {
        headerName: 'Clinician',
        field: 'clinicians',
        valueFormatter: ({ value }: { value: Clinician[] }) => {
          if (!value?.length) return '';

          return getClinicianName(value[0]);
        },
      },
      {
        headerName: 'Sample Received',
        field: 'sample_received_at',
        filter: 'agDateColumnFilter',
        valueFormatter: ({ value }: { value: string }) => {
          if (!value) return '';

          return dayjs.utc(value).format('MM/DD/YYYY');
        },
        filterParams: {
          comparator: dateColumnComparator,
        },
      },
    ] as ColDef[];

    return cols;
  }, [displayNameByHideState, onPatientNameAction, breakpoint]);

  const defaultColDef = useMemo(() => {
    return {
      filter: 'agTextColumnFilter',
      suppressFloatingFilterButton: true,
      floatingFilter: true,
      suppressHeaderMenuButton: true,
    };
  }, []);

  const onGridReady = useCallback((params: { api: GridApi }) => {
    setGridApi(params.api);
  }, []);

  const onSortChanged = useCallback(() => {
    if (gridApi) {
      const colState = gridApi.getColumnState();
      const sortState = colState
        .filter(function (s) {
          return s.sort != null;
        })
        .map(function (s) {
          return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
        });

      setSortModel(sortState);
    }
  }, [gridApi]);

  useEffect(() => {
    gridApi?.applyColumnState({
      state: sortModel,
      defaultState: { sort: null },
    });
  }, [gridApi, sortModel, columns]);

  return (
    <div
      className={`requisitions-table-container ${loading || loadingError ? 'status-panel' : 'ag-theme-quartz'}`}
      data-testid='requisitionsTable'
    >
      {(loading || loadingError) && (
        <RequisitionsStatusPanel loading={loading} loadingError={loadingError} onRetry={onRetry} />
      )}
      {!loading && !loadingError && (
        <AgGridReact<ExtendedRequisition>
          gridOptions={{
            onCellKeyDown: (event: CellKeyDownEvent<ExtendedRequisition, unknown>) => {
              const keyEvent = event.event as KeyboardEvent;

              if (keyEvent && (keyEvent.key === 'Enter' || keyEvent.key === ' ')) {
                // @ts-ignore
                keyEvent.target?.querySelector('button')?.click();
              }
            },
          }}
          enableCellTextSelection
          ensureDomOrder
          rowData={tableData}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          suppressDragLeaveHidesColumns
          reactiveCustomComponents
          getRowStyle={({ node }) => {
            if ((node?.rowIndex ?? 0) % 2 === 1) {
              return { background: '#FCFCFC' };
            }
          }}
          onGridReady={onGridReady}
          onSortChanged={onSortChanged}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
        />
      )}
    </div>
  );
};

export default RequisitionTable;
