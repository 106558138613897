import { Requisition } from '@customTypes/requisition';

import ExpandableSection from '../ExpandableSection/ExpandableSection';
import NoDetailContent from '../NoDetailContent/NoDetailContent';
import RequisitionDocuments from './RequisitionDocuments';

import './PatientDetailDocuments.scss';

interface IProps {
  requisitions: Requisition[];
  numOfDocuments: number;
}

const PatientDetailDocuments = ({ requisitions, numOfDocuments }: IProps) => {
  return (
    <ExpandableSection
      id='documents'
      expandableContentId='documentsContent'
      headerTitle={`Documents (${numOfDocuments})`}
    >
      {!requisitions.length && (
        <NoDetailContent content='There are no documents available for download at this time.' />
      )}
      {requisitions.length > 0 && (
        <div className='gap-4 documents-list'>
          {requisitions.map(requisition => (
            <RequisitionDocuments key={requisition.requisition_id} requisition={requisition} />
          ))}
        </div>
      )}
    </ExpandableSection>
  );
};

export default PatientDetailDocuments;
