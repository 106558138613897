import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { ToastContainer } from 'react-bootstrap';

import Toast from '@components/Toast/Toast';
import { ToastItem } from '@customTypes/toast';

interface ToastContextProps {
  toasts: ToastItem[];
  setToasts: React.Dispatch<React.SetStateAction<ToastItem[]>>;
}

interface Props {
  children: React.ReactNode;
}

const ToastContext = createContext<ToastContextProps>({} as ToastContextProps);

export const ToastProvider: React.FC<Props> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const memoedContextValue = useMemo(
    () => ({
      toasts,
      setToasts,
    }),
    [toasts],
  );

  const onToastClose = useCallback((toastIndex: number) => {
    setToasts(toasts => toasts.filter((_, index) => index !== toastIndex));
  }, []);

  return (
    <ToastContext.Provider value={memoedContextValue}>
      {children}

      {toasts.length > 0 && (
        <ToastContainer aria-live='polite' aria-atomic='true' className='p-3 position-fixed cp-toast-container'>
          {toasts.map((toastItem, index) => (
            <Toast key={`${toastItem.errorString}-${index}`} {...toastItem} onClose={() => onToastClose(index)} />
          ))}
        </ToastContainer>
      )}
    </ToastContext.Provider>
  );
};

export default function useToast() {
  return useContext(ToastContext);
}
