import { useContext, useMemo, useRef } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faHandshake } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faPlus, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { Container, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as FullLogoSvg } from '@assets/images/full_logo.svg';
import Button from '@components/Button/IconButton';
import Placeholder from '@components/Placeholder/Placeholder';
import useAuth from '@hooks/useAuth';
import { useClinician } from '@hooks/useClinician';
import { Context } from '@store/store';

import './HeaderNav.scss';

const Header = () => {
  const [state] = useContext(Context);
  const { clinicianName } = useClinician(state.currentClinician);
  const { onLogin, onLogout, isLoggedIn, hasPartnershipOrdering } = useAuth();
  const navigate = useNavigate();
  const { pathname, state: locationState } = useLocation();

  const errorCode = useMemo(() => locationState?.errorCode, [locationState?.errorCode]);
  const logoutRef = useRef<HTMLButtonElement | null>(null);

  return (
    <header className={`fixed-top ${hasPartnershipOrdering && 'partnership-ordering'}`}>
      {hasPartnershipOrdering && (
        <div className='partnership-ordering-banner'>
          <Container fluid='xxl'>
            <div className='d-flex text-primary'>
              <FontAwesomeIcon icon={faHandshake as IconDefinition} fontSize={16} className='me-2' />
              <p className='mb-0 text-primary fw-bold'>You have access to partnership ordering</p>
            </div>
          </Container>
        </div>
      )}
      <Navbar expand='md' className='bg-white border-bottom border-cyan border-2 box-shadow-100 py-3'>
        <Container fluid='xxl'>
          <Navbar.Brand href='/' className='p-0'>
            <FullLogoSvg height={40} />
          </Navbar.Brand>
          <Navbar.Toggle className='toggle-btn' />
          <Navbar.Collapse className='justify-content-end mb-n2 mt-4 mb-md-0 mt-md-0'>
            {isLoggedIn && (
              <Nav className='d-flex flex-column flex-md-row align-items-start align-items-md-center gap-3'>
                {errorCode !== 401 && (
                  <>
                    <Button
                      icon={faPlus}
                      className='d-flex justify-content-center align-items-center new-order-btn'
                      disabled={pathname === '/requisitions/create'}
                      onClick={() => navigate('/requisitions/create')}
                      data-testid='newOrderBtn'
                    >
                      New Order
                    </Button>
                    <div className='vr d-none d-md-block'></div>
                    <div className='d-flex align-items-center order-first order-md-0'>
                      <FontAwesomeIcon icon={faUserDoctor} fontSize={24} className='me-2 profile-icon' />
                      <div
                        // eslint-disable-next-line max-len
                        className={`d-flex flex-column flex-sm-row flex-md-column gap-0 gap-sm-2 clinian-info ${!state.currentClinician ? 'gap-md-1 no-info' : 'gap-md-0'}`}
                      >
                        {state.isFetchingClinician && (
                          <>
                            <Placeholder size='xs' className='w-75' />
                            <Placeholder size='xs' className='w-100' />
                          </>
                        )}
                        {!state.isFetchingClinician && state.currentClinician && (
                          <>
                            <p className='fs-body fw-bold text-start mb-0' data-testid='clinicianName'>
                              {clinicianName}
                            </p>
                            <p className='fs-body text-second text-start mb-0'>
                              {state.currentClinician?.clinic_name ?? ''}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='vr logout-border'></div>
                  </>
                )}
                <OverlayTrigger placement='bottom' overlay={<Tooltip>Logout</Tooltip>}>
                  <Button
                    variant='outline-secondary'
                    className='d-none d-md-flex align-items-center py-2'
                    onClick={onLogout}
                    ref={logoutRef}
                  >
                    <FontAwesomeIcon icon={faArrowRightFromBracket} fontSize={20} />
                  </Button>
                </OverlayTrigger>
                <Nav.Link onClick={onLogout} className='d-md-none text-primary mb-2'>
                  Logout
                </Nav.Link>
              </Nav>
            )}
            {!isLoggedIn && <Nav.Link onClick={() => onLogin()}>Login</Nav.Link>}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
