import { ResultsType } from '@customTypes/app';
import { Order, OrderStatus } from '@customTypes/order';

import { getResultType } from './common';

export const isOrderReported = (order: Order): boolean => {
  return [OrderStatus.COMPLETED, OrderStatus.REPORTED].includes(order.status);
};

export const getOrderResult = (order?: Order | null): ResultsType => {
  return getResultType(order?.results ?? []);
};
