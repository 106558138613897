import { IUsername } from '@customTypes/user';

export const getPatientName = (name?: IUsername): string => {
  if (!name) return '';

  return name.first + ' ' + name.last;
};

/**
 * We might need to find a better solution to extract a order test name
 *
 * @param originName product name
 * @returns
 */
export const getPatientOrderTestName = (originName: string): string[] => {
  const result: string[] = [];
  let str: string = originName;

  // Extract MyOme if exists
  if (str.toLowerCase().startsWith('myome ')) {
    result.push('MyOme');
    str = str.slice(6);
  }
  // Find a first index of comma so that it can be separated into two lines
  const firstCommaIndex = str.indexOf(', ');

  if (firstCommaIndex > -1) {
    result.push(str.slice(0, firstCommaIndex), str.slice(firstCommaIndex + 2));
  } else {
    result.push(str);
  }

  return result;
};
