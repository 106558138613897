import Keycloak from 'keycloak-js';

import { Clinician } from '@customTypes/clinician';
import { IPatientDetailRes } from '@customTypes/patient';
import { FormattedProduct } from '@customTypes/product';
import { Requisition } from '@customTypes/requisition';
import { TaskFormInterface } from '@components/RequisitionCreate/JSONSchemaForm/JSONSchemaForm';

export interface AppState {
  requisitions?: Requisition[];
  isFetchingClinician?: boolean;
  currentClinician?: Clinician;
  keycloak?: Keycloak;
  newRequisition?: { requisition: Requisition; patientId?: string } | null;
  formattedProductsList: FormattedProduct[];
  requisitionTaskForm?: TaskFormInterface;
  patientsMap: Record<string, IPatientDetailRes | null>;
}

type SetRequisitions = { type: 'SET_REQUISITIONS'; payload: Requisition[] };
type SetNewRequisition = {
  type: 'SET_NEW_REQUISITION';
  payload: { requisition: Requisition; patientId?: string } | null;
};
type SetCurrentClinician = { type: 'SET_CURRENT_CLINICIAN'; payload: Clinician };
type SetKeycloak = { type: 'SET_KEYCLOAK'; payload: Keycloak };
type SetFormattedProducts = { type: 'SET_FORMATTED_PRODUCTS_LIST'; payload: FormattedProduct[] };
type SetClinicianFetching = { type: 'SET_CLINICIAN_FETCHING'; payload: boolean };
type SetRequisitionTaskForm = { type: 'SET_REQUISITION_TASK_FORM'; payload: TaskFormInterface | undefined };
type SetPatientDetail = {
  type: 'SET_PATIENT_DETAIL';
  payload: { patientId: string; result: IPatientDetailRes | null };
};

export type AppActions =
  | SetRequisitions
  | SetCurrentClinician
  | SetKeycloak
  | SetFormattedProducts
  | SetNewRequisition
  | SetClinicianFetching
  | SetRequisitionTaskForm
  | SetPatientDetail;

export enum ResultsType {
  ACTIONABLE_RESULT = 'Actionable Result',
  AVERAGE_RISK_OR_NEGATIVE = 'Average risk or negative',
  NO_DATA_AVAILABLE = 'No data available',
}
