import { useMemo } from 'react';

import dayjs from 'dayjs';

import IconButton from '@components/Button/IconButton';
import { DATE_FORMAT } from '@constants/constants';
import { Requisition } from '@customTypes/requisition';
import { getIconByResult } from '@helpers/common';
import { getOrderResult } from '@helpers/order';
import { useDownload } from '@hooks/useDownload';

interface IProps {
  requisition: Requisition;
}

export default function RequisitionDocuments({ requisition }: IProps) {
  const { onDownload, onClinicalSummaryLetterDownload } = useDownload();

  const orders = useMemo(
    () =>
      requisition.orders.map(order => ({
        ...order,
        icon: getIconByResult(getOrderResult(order)),
        linkText: order.product_name + ' Report',
      })),
    [requisition.orders],
  );

  return (
    <div className='d-flex flex-column gap-3 bg-light p-2 pb-3 rounded-3 requisition-documents'>
      <div className='d-flex flex-column align-items-start'>
        <span className='fs-body small fw-bold text-second'>Order ID:</span>
        <p className='mb-0 fs-5 lh-xs fw-bold'>{requisition.requisition_id}</p>
      </div>

      <ul className='mb-0 ps-0 d-flex flex-column gap-3 list-style-none'>
        {orders.map(order => (
          <li key={order.order_id} className='d-flex flex-column align-items-start px-1 gap-2'>
            <div className='d-flex align-items-end gap-2'>
              <IconButton
                variant='link'
                className='p-0 text-start'
                onClick={() => onDownload(order, requisition.requisition_id)}
              >
                {order.linkText}
              </IconButton>
            </div>
            <p className='mb-0 text-second'>{dayjs.utc(order.reported_at).format(DATE_FORMAT)}</p>
          </li>
        ))}
        {!!requisition.clinical_summary_letter && (
          <li className='d-flex flex-column align-items-start px-1 gap-2'>
            <div className='d-flex align-items-end gap-2'>
              <IconButton
                variant='link'
                className='p-0 text-start'
                onClick={() => onClinicalSummaryLetterDownload(requisition)}
              >
                Clinical Summary Letter
              </IconButton>
            </div>
            <p className='mb-0 text-second'>
              {dayjs.utc(requisition.clinical_summary_letter.completed_on).format(DATE_FORMAT)}
            </p>
          </li>
        )}
      </ul>
    </div>
  );
}
