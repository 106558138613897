import { useMemo } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Card, Container, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBuoyMooring } from '@fortawesome/pro-duotone-svg-icons';

import Header from '@components/Header/HeaderNav';
import Footer from '@components/Footer/Footer';
import useAuth from '@hooks/useAuth';

import { ErrorMessageByCode, ERROR_MESSAGES_BY_CODE, DEFAULT_ERROR_MESSAGE } from './constants';
import './errorPage.scss';

export default function ErrorPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, onLogout } = useAuth();
  const errorCode = useMemo(() => location.state?.errorCode, [location.state?.errorCode]);

  const { title, subtitle, code, errorString }: ErrorMessageByCode = useMemo(() => {
    const data = ERROR_MESSAGES_BY_CODE.find(item => item.code === errorCode);

    return data ?? DEFAULT_ERROR_MESSAGE;
  }, [errorCode]);

  const onBtnClick = () => {
    if (errorCode === 401) {
      onLogout();
    } else {
      navigate(isLoggedIn ? '/requisitions' : '/');
    }
  };

  return (
    <Stack>
      <Header />
      <div className='d-flex flex-grow-1 position-relative error-container'>
        <Container fluid='xxl' className='d-flex flex-column py-4 error-content'>
          <Card className='flex-grow-1 py-5'>
            <Card.Body className='d-flex'>
              <Stack gap={4} className='justify-content-center'>
                <FontAwesomeIcon icon={faBuoyMooring as IconDefinition} color='#003595' fontSize={64} />
                <p className='mb-0 fs-3 lh-xs fw-bold error-title'>{title}</p>
                <div className='fs-6 error-subtitle'>
                  {subtitle}
                  <span className='d-block mt-2 fs-body small fw-bold'>{`${code}: ${errorString}`}</span>
                </div>
                <Button onClick={onBtnClick} className='align-self-center'>
                  {errorCode === 401 ? 'Logout' : 'Back to home'}
                </Button>
              </Stack>
            </Card.Body>
          </Card>
        </Container>
      </div>

      <Footer />
    </Stack>
  );
}
