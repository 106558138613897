import { useMemo } from 'react';
import { Button, Toast as RBToast, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { faSquareExclamation } from '@fortawesome/pro-solid-svg-icons';

import IconButton from '@components/Button/IconButton';
import { ToastItem, ToastType } from '@customTypes/toast';

import { BLOCKED_BY_BROWSER_CONTENT, DEFAULT_ERROR_CONTENT } from './consts';
import './Toast.scss';

interface IToastProps extends ToastItem {
  onClose: () => void;
}

const renderErrorString = (errorString?: string) => {
  if (!errorString) return null;

  return <p className='mb-0 fw-bold fs-body small'>{errorString}</p>;
};

const renderCustomActions = (onClose: () => void) => {
  const onReload = () => {
    window.location.reload();
  };

  return (
    <Stack direction='horizontal' gap={2}>
      <IconButton icon={faRotate} size='sm' variant='primary' onClick={onReload}>
        Reload
      </IconButton>
      <Button size='sm' variant='secondary' onClick={onClose}>
        Close
      </Button>
    </Stack>
  );
};

export default function Toast({
  type = ToastType.BASIC,
  headerTitle = 'Server Issue',
  content,
  errorString = 'Unknown Error',
  onClose,
}: IToastProps) {
  const errorContent = useMemo(() => {
    if (content) return content;

    if (type === ToastType.ACTION_BLOCKED) return BLOCKED_BY_BROWSER_CONTENT;

    return DEFAULT_ERROR_CONTENT;
  }, [content, type]);

  return (
    <RBToast show onClose={onClose} className='text-start cp-toast' data-testid='toastContainer'>
      {type !== ToastType.CUSTOM_ACTIONS && (
        <RBToast.Header data-testid='toastHeader'>
          <FontAwesomeIcon icon={faSquareExclamation as IconDefinition} fontSize={21} color='red' />
          <p className='ms-2 mb-0 fw-semibold me-auto fs-6 lh-sm'>{headerTitle}</p>
        </RBToast.Header>
      )}
      <RBToast.Body className='d-flex flex-column align-items-start gap-2 cp-toast-body'>
        <p className='fs-6 mb-0'>{errorContent}</p>
        {type === ToastType.CUSTOM_ACTIONS && renderErrorString(errorString)}
        {type !== ToastType.ACTION_BLOCKED && <hr className='my-0 align-self-stretch' />}
        {type !== ToastType.CUSTOM_ACTIONS && renderErrorString(errorString)}
        {type === ToastType.CUSTOM_ACTIONS && renderCustomActions(onClose)}
      </RBToast.Body>
    </RBToast>
  );
}
