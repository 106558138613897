import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { SENTRY_DSN, envInfo } from '@constants/constants';

import App from './App.tsx';

import './index.css';

const sentryDsn = envInfo.sentry_enabled ? SENTRY_DSN : null;
Sentry.init({
  dsn: sentryDsn,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', envInfo.baseurl],
  environment: envInfo.keycloak_realm,
  sendDefaultPii: false,
  release: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <App />
  </Router>,
);
