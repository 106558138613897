import { forwardRef } from 'react';

import RbButton, { ButtonProps as RbButtonProps } from 'react-bootstrap/Button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IconButtonProps extends RbButtonProps {
  icon?: IconProp;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, icon, className = '', ...buttonProps }, ref) => {
    return (
      <RbButton ref={ref} className={`d-flex align-items-center ${className}`} {...buttonProps}>
        {icon ? <FontAwesomeIcon icon={icon} fontSize={16} className='me-2' /> : null}
        {children}
      </RbButton>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
