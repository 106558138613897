import { AppActions, AppState } from '@customTypes/app';

const Reducer = (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case 'SET_REQUISITIONS':
      return {
        ...state,
        requisitions: action.payload,
      };
    case 'SET_NEW_REQUISITION':
      return {
        ...state,
        newRequisition: action.payload,
      };
    case 'SET_CURRENT_CLINICIAN':
      return {
        ...state,
        currentClinician: action.payload,
      };
    case 'SET_KEYCLOAK':
      return {
        ...state,
        keycloak: action.payload,
      };
    case 'SET_FORMATTED_PRODUCTS_LIST':
      return {
        ...state,
        formattedProductsList: action.payload,
      };
    case 'SET_CLINICIAN_FETCHING':
      return {
        ...state,
        isFetchingClinician: action.payload,
      };
    case 'SET_REQUISITION_TASK_FORM':
      return {
        ...state,
        requisitionTaskForm: action.payload,
      };
    case 'SET_PATIENT_DETAIL':
      return {
        ...state,
        patientsMap: {
          ...state.patientsMap,
          [action.payload.patientId]: action.payload.result,
        },
      };
    default:
      return state;
  }
};

export default Reducer;
