import { ReactNode } from 'react';

export enum ToastType {
  BASIC,
  CUSTOM_ACTIONS,
  ACTION_BLOCKED,
}

export interface ToastItem {
  type?: ToastType;
  content?: string | ReactNode;
  headerTitle?: string;
  errorString?: string;
}
