import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowRightToBracket } from '@fortawesome/pro-solid-svg-icons';

import loginLgImage from '@assets/images/login-images/login-lg.webp';
import loginXlImage from '@assets/images/login-images/login-xl.webp';
import loginXsImage from '@assets/images/login-images/login-xs.webp';
import loginSmImage from '@assets/images/login-images/login-sm.webp';
import loginMdImage from '@assets/images/login-images/login-md.webp';

import HeaderNav from '@components/Header/HeaderNav';
import Footer from '@components/Footer/Footer';
import useAuth from '@hooks/useAuth';

import './login.scss';

export default function PortalEntry() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, onLogin } = useAuth();

  useEffect(() => {
    const redirectUrl = location.state?.pathname;

    // Redirect to login page automatically
    if (redirectUrl && !isLoggedIn) {
      onLogin(redirectUrl);
    }
  }, [location.state?.pathname, onLogin, isLoggedIn]);

  return (
    <Stack data-testid='login_page' className='portal-entry-page'>
      <HeaderNav />
      <main className='login-page-wrapper'>
        <div className='login-left-half'>
          <section className='left-half-content' id='login-section'>
            <h1 className='login-title'>Empowering Health Decisions through Genomics</h1>
            <Button variant='primary' size='lg' className='login-btn' onClick={() => onLogin()} data-testid='loginBtn'>
              {isLoggedIn ? (
                'Go to Home'
              ) : (
                <>
                  Login <FontAwesomeIcon icon={faArrowRightToBracket as IconDefinition} className='ms-1' />
                </>
              )}
            </Button>
            <Button variant='outline-primary' size='sm' className='mt-4' onClick={() => navigate('/signup')}>
              Sign up for an account
            </Button>
          </section>
        </div>
        <div className='login-right-half'>
          <img
            data-sizes='auto'
            alt='doctor seated at desk and smiling at patient'
            src={loginXlImage}
            srcSet={`
              ${loginXsImage} 440w,
              ${loginSmImage} 767w,
              ${loginMdImage} 991w,
              ${loginLgImage} 1000w,
              ${loginXlImage} 1236w
            `}
            className='login-img'
          />
        </div>
      </main>
      <Footer />
    </Stack>
  );
}
