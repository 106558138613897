import { Formik } from 'formik';
import { Button, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { EMAIL_REGEX } from '@constants/constants';
import { ISignupForm, UserRole } from '@customTypes/user';
import useAuth from '@hooks/useAuth';
import { postOnboarding } from '@store/actions';

import SignupForm from './components/SignupForm/SignupForm';

import './Signup.scss';

const profileSchema = yup.object().shape({
  name: yup.object().shape({
    first: yup.string().required('First name is required.'),
    middle: yup.string(),
    last: yup.string().required('Last name is required.'),
    credentials: yup.string(),
  }),
  role: yup
    .mixed<UserRole>()
    .oneOf(Object.values(UserRole), 'Invalid role selected.')
    .required('Role selection is required.'),
  npi: yup.string().when('role', (role: unknown) => {
    if (Array.isArray(role) && role[0] === UserRole.ORDERING_PROVIDER) {
      return yup
        .string()
        .matches(/^\d{9,10}$/, 'NPI must be 9 or 10 digits.')
        .required('Please provide an NPI.');
    } else {
      return yup
        .string()
        .matches(/^\d{9,10}$/, 'NPI must be 9 or 10 digits.')
        .notRequired();
    }
  }),
  email: yup
    .string()
    .matches(EMAIL_REGEX, ({ value }: { value: string }) => `${value} is invalid. Please provide a valid email.`)
    .required('Please provide a valid email.'),
  phone: yup.string().matches(/^\+?[0-9]{6,}$/, 'Please provide a valid phone number.'),
  fax: yup.string().matches(/^\+?[0-9]{6,}$/, 'Please provide a valid fax number.'),
});

const organizationSchema = yup.object().shape({
  name: yup.string().required('Organization name is required.'),
  phone: yup
    .string()
    .matches(/^\+?[0-9]{6,}$/, 'Please provide a valid phone number.')
    .required('Please provide a valid phone number.'),
  fax: yup.string().matches(/^\+?[0-9]{6,}$/, 'Please provide a valid fax number.'),

  address: yup.object().shape({
    street_address: yup.string().required('Address is required.'),
    street_address_2: yup.string(),
    city: yup.string().required('City is required.'),
    region: yup.string().required('Region is required.'),
    postal_code: yup.string().required('Postal code is required.'),
  }),
});

const validationSchema = yup.object().shape({
  signup_code: yup.string(),
  provider: profileSchema,
  has_teammates: yup.boolean().required(),
  teammates: yup.array().of(profileSchema),
  organization: organizationSchema,
});

export default function Signup() {
  const { onLogin } = useAuth();
  const navigate = useNavigate();

  const onSubmit = async (data: ISignupForm) => {
    await postOnboarding(data);
    navigate('/account-review');
  };

  return (
    <Stack className='d-flex flex-column rounded-1 bg-white box-shadow-50 signup-page-container'>
      <section className='d-flex flex-column align-items-start signup-page-header'>
        <h1 className='fw-bold page-title'>Sign up for an account</h1>
        <p className='d-flex align-items-center mt-md-1 fs-body fw-normal text-second mb-0 page-subtitle'>
          Already have an account?{' '}
          <Button
            as='a'
            size='sm'
            variant='link'
            className='d-flex align-items-center p-0 ms-2 signin-link'
            onClick={() => onLogin()}
          >
            Sign in.
          </Button>
        </p>
      </section>

      <Formik
        validationSchema={validationSchema}
        validateOnBlur
        onSubmit={onSubmit}
        initialValues={{
          provider: {
            name: {
              first: '',
              last: '',
            },
            role: '' as UserRole,
            email: '',
          },

          has_teammates: false,
          teammates: [],

          organization: {
            name: '',
            phone: '',
            address: {
              street_address: '',
              city: '',
              region: '',
              postal_code: '',
            },
          },
        }}
      >
        <SignupForm />
      </Formik>
    </Stack>
  );
}
