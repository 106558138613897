import { useMemo } from 'react';
import { Placeholder as RBPlaceholder, PlaceholderProps as RBPlaceholderProps } from 'react-bootstrap';

import './Placeholder.scss';

interface PlaceholderProps extends Omit<RBPlaceholderProps, 'size'> {
  size?: 'xs' | 'sm' | 'lg' | 'xl' | '2xl' | '3xl';
}

export default function Placeholder(props: PlaceholderProps) {
  const { animation, className = '', size, ...otherProps } = props ?? {};
  const sizeClass = useMemo(() => {
    if (!size) return '';

    return `placeholder-${size}`;
  }, [size]);

  return (
    <RBPlaceholder animation={animation ?? 'glow'} className={`${className} d-block placeholder-root`}>
      <RBPlaceholder className={`w-100 h-100 ${sizeClass}`} {...otherProps} />
    </RBPlaceholder>
  );
}
