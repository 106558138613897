import { lazy, Suspense, useMemo, useState } from 'react';

import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Breadcrumb, Card, Stack } from 'react-bootstrap';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

import IconButton from '@components/Button/IconButton';
import CancelConfirmDialog from '@components/RequisitionCreate/CancelConfirmDialog/CancelConfirmDialog';
import usePatientDetail from '@hooks/usePatientDetail';

import './OrderNewReport.scss';

const RequisitionJSONSchemaForm = lazy(
  () => import('@components/RequisitionCreate/RequisitionJSONSchemaForm/RequisitionJSONSchemaForm'),
);

export default function OrderNewReport() {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const patientId = useMemo(() => params?.id, [params]);
  const requisitionId = useMemo(() => params?.reqId, [params]);
  const campaignCode = useMemo(() => searchParams.get('campaign'), [searchParams]) ?? undefined;

  const { patientName, loading } = usePatientDetail(patientId);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const moveToPatientDetail = () => {
    navigate(`/patients/${patientId}`);
  };

  if (loading) {
    return null;
  }

  return (
    <Suspense>
      <Stack gap={4} className='layout-single-col-py create-new-order-report-container'>
        <Breadcrumb listProps={{ className: 'mb-0' }}>
          <Breadcrumb.Item onClick={moveToPatientDetail}>{patientName}</Breadcrumb.Item>
          <Breadcrumb.Item active>Order New Report</Breadcrumb.Item>
        </Breadcrumb>
        <Card as='section' className='text-start px-4 pb-4 pt-2 box-shadow-50 position-static'>
          <Card.Title as='h1' className='mt-md-1 fw-bold page-title'>
            Order New Report
          </Card.Title>
          <Card.Subtitle className='mt-md-1 fs-body fw-normal text-second mb-0 page-subtitle' as='p'>
            Fill out the form in each step as provided. Use the Next button to continue to the next step, and the Submit
            button when all steps are completed. Some product selections may not require additional information.
          </Card.Subtitle>
          <Card.Body className='p-0 pt-4'>
            <RequisitionJSONSchemaForm selectedCampaignCode={campaignCode} selectedRequisitionId={requisitionId} />
          </Card.Body>
          <Card.Footer className='bg-white p-0 pt-3 mt-4 border-top'>
            <IconButton variant='outline-dark' icon={faArrowLeftLong} onClick={() => setShowConfirmDialog(true)}>
              Cancel and return
            </IconButton>
          </Card.Footer>
        </Card>
      </Stack>
      {showConfirmDialog && (
        <CancelConfirmDialog
          isOrderNewReport
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={moveToPatientDetail}
        />
      )}
    </Suspense>
  );
}
