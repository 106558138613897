import { useEffect, useMemo, useRef } from 'react';

import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { useFormikContext } from 'formik';
import { get, camelCase } from 'lodash';
import { Col, Form } from 'react-bootstrap';

import { envInfo } from '@constants/constants';
import type { IForm, IFormField } from '@customTypes/form';
import { camelToTitleCase } from '@helpers/string';

const googleMapsLibrariesToBeLoaded: Libraries = ['places'];

const generateName = (name: string, prefix = ''): string => {
  return prefix ? `${prefix}.${name}` : name;
};

export default function FormField<T extends IForm>({
  type = 'text',
  prefix = '',
  name,
  cols,
  className = '',
  label,
  disabled = false,
  required = false,
  helperText = '',
  address = false,
}: IFormField<T>) {
  const { touched, errors, values, handleBlur, handleChange, setFieldValue, validateForm, setTouched } =
    useFormikContext<T>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const groupClassName = useMemo(() => {
    if (cols === 4) {
      return 'col-12 col-lg-4';
    }
    if (cols === 8) {
      return 'col-12 col-lg-8';
    }

    return className;
  }, [cols, className]);
  const formControlName = useMemo(() => generateName(name, prefix), [prefix, name]);
  const error = useMemo(() => get(errors, formControlName), [formControlName, errors]);
  const isDirty = useMemo(() => get(touched, formControlName), [formControlName, touched]);
  const fieldValue = useMemo(() => get(values, formControlName), [values, formControlName]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: envInfo.googleMapsApiKey,
    libraries: googleMapsLibrariesToBeLoaded,
  });

  useEffect(() => {
    if (address && isLoaded && window.google && inputRef.current) {
      const autocompleteInstance = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ['address'],
      });

      autocompleteInstance.addListener('place_changed', () => {
        const selectedPlace: google.maps.places.PlaceResult = autocompleteInstance.getPlace();
        const addressComponents = selectedPlace.address_components;

        if (addressComponents) {
          let streetAddress = '';
          const streetNumber = addressComponents.find(obj => obj.types.includes('street_number'));
          const streetName = addressComponents.find(obj => obj.types.includes('route'));
          const city = addressComponents.find(obj => obj.types.includes('locality'))?.long_name;
          const region = addressComponents.find(obj => obj.types.includes('administrative_area_level_1'))?.short_name;
          const postalCode = addressComponents.find(obj => obj.types.includes('postal_code'))?.long_name;

          if (streetNumber) streetAddress += streetNumber.long_name + ' ';
          if (streetName) streetAddress += streetName.short_name;
          // inputRef.current!.value = streetAddress;
          setFieldValue(formControlName, streetAddress);
          setFieldValue(generateName('address.city', prefix), city);
          setFieldValue(generateName('address.region', prefix), region);
          setFieldValue(generateName('address.postal_code', prefix), postalCode);
        } else {
          setFieldValue(formControlName, '');
          setFieldValue(generateName('address.city', prefix), '');
          setFieldValue(generateName('address.region', prefix), '');
          setFieldValue(generateName('address.postal_code', prefix), '');
        }

        setTimeout(() => validateForm(), 300);
      });
    }
  }, [isLoaded, prefix, name, address, validateForm, setTouched, setFieldValue, formControlName]);

  return (
    <Form.Group as={Col} controlId={`input_${formControlName}`} className={groupClassName}>
      <Form.Label className={required ? 'required' : ''}>{label ?? camelToTitleCase(camelCase(name))}</Form.Label>
      <Form.Control
        ref={inputRef}
        type={type}
        name={formControlName}
        onBlur={handleBlur}
        onChange={handleChange}
        value={fieldValue ?? ''}
        disabled={disabled}
        aria-describedby={`input_${formControlName}Desc`}
        isValid={isDirty && !error}
        isInvalid={isDirty && !!error}
      />
      {!!helperText && <Form.Text id={`input_${formControlName}Desc`}>{helperText}</Form.Text>}
      {!!error && (
        <Form.Control.Feedback as='small' role='alert' type='invalid'>
          {error as string}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
