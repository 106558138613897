import { useContext, useMemo } from 'react';

import { Container } from 'react-bootstrap';

import catalystBannerImg from '@assets/images/catalyst-logo-full-horizontal_white.png';
import { CATALYST_CAMPAIGN_CODE } from '@constants/constants';
import { Context } from '@store/store';

import './PartnerBanner.scss';

export default function PartnerBanner() {
  const [state] = useContext(Context);

  const isCatalyst = useMemo(
    () => state.requisitionTaskForm?.campaign?.campaign_code === CATALYST_CAMPAIGN_CODE,
    [state.requisitionTaskForm?.campaign?.campaign_code],
  );
  if (!state.requisitionTaskForm?.campaign?.campaign_code) {
    return null;
  }

  return (
    <div title='Partner Banner' className={`banner-container bg-primary ${isCatalyst && 'catalyst'}`}>
      <Container fluid='xxl' className='h-100'>
        <div className='d-flex align-items-center h-100 text-white banner-content'>
          {isCatalyst && <img src={catalystBannerImg} alt='Catalyst Banner Image' height={24} />}
          {!isCatalyst && state.requisitionTaskForm?.campaign?.name && (
            <>
              <p className='mb-0 fs-6 fs-lg-5 lh-xs fw-bold product-name'>
                {state.requisitionTaskForm?.campaign?.name}
              </p>
              <p className='fw-medium mb-0 text-uppercase company-brand'>
                {state.requisitionTaskForm?.campaign?.description
                  ? state.requisitionTaskForm?.campaign?.description
                  : 'Powered by MyOme®'}
              </p>
            </>
          )}
        </div>
      </Container>
    </div>
  );
}
