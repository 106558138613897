import Keycloak from 'keycloak-js';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Footer from '@components/Footer/Footer';
import Header from '@components/Header/HeaderNav';
import LoadingIndicator from '@components/LoadingIndicator/LoadingIndicator';
import { envInfo, KEYCLOAK_CLIENT_ID, KEYCLOAK_URL } from '@constants/constants';
import useAuth from '@hooks/useAuth';
import { fetchCurrentClinician, fetchLoggedInUserId } from '@store/actions';
import { Context } from '@store/store';

import { updateAxiosToken } from '@hooks/useAxios';
import routes from './routes';

const RouterProvider = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [, dispatch] = useContext(Context);
  const keycloakInstance = new Keycloak({
    url: KEYCLOAK_URL,
    realm: envInfo.keycloak_realm,
    clientId: KEYCLOAK_CLIENT_ID,
  });
  const { isLoggedIn, onLogout, onInvalidAuth } = useAuth();

  useEffect(() => {
    if (isLoggedIn && pathname === '/') {
      // Redirect to /requestions page
      navigate('/requisitions');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, pathname]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  useEffect(() => {
    const initKeycloak = async () => {
      keycloakInstance
        .init({
          onLoad: 'check-sso',
          pkceMethod: 'S256',
          silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
        })
        .then(() => {
          setLoading(false);
        });
      dispatch({ type: 'SET_KEYCLOAK', payload: keycloakInstance });

      keycloakInstance.onTokenExpired = () => {
        keycloakInstance
          .updateToken(30)
          .then(() => updateAxiosToken(keycloakInstance.token))
          .catch(onLogout);
      };
    };

    initKeycloak();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClinicianInfo = useCallback(async () => {
    try {
      if (isLoggedIn) {
        const clinicianId = await fetchLoggedInUserId();
        dispatch({ type: 'SET_CLINICIAN_FETCHING', payload: true });

        if (clinicianId) {
          fetchCurrentClinician(clinicianId).then(clinician => {
            dispatch({ type: 'SET_CURRENT_CLINICIAN', payload: clinician });
          });
        } else {
          onInvalidAuth();
        }
      }
    } catch (err) {
      console.error('Error on get clinician information: ', err);
      onInvalidAuth();
    } finally {
      dispatch({ type: 'SET_CLINICIAN_FETCHING', payload: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    getClinicianInfo();
  }, [getClinicianInfo]);

  if (loading) {
    return <LoadingIndicator />;
  } else {
    return (
      <Routes>
        {routes
          .filter(route => {
            if (route.hide) return false;
            if (isLoggedIn && route.onlyAuth) return false;
            if (!isLoggedIn && route.private) return false;

            return true;
          })
          .map(route => (
            <Route
              path={route.path}
              element={
                route.newLayout ? (
                  <>
                    <Header />
                    {route.PageContainer && (
                      <route.PageContainer>
                        <Container fluid='xxl' className='d-flex flex-grow-1 position-relative'>
                          {route.element}
                        </Container>
                      </route.PageContainer>
                    )}
                    {!route.PageContainer && (
                      <Container fluid='xxl' className='flex-grow-1 position-relative'>
                        {route.element}
                      </Container>
                    )}
                    <Footer />
                  </>
                ) : (
                  route.element
                )
              }
              key={route.path}
            />
          ))}

        <Route path='*' element={<Navigate to='/' state={{ pathname }} replace />} />
      </Routes>
    );
  }
};

export default RouterProvider;
