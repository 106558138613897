import './PageContainer.scss';

interface IProps {
  children: React.ReactNode;
}

const PageContainer = ({ children }: IProps) => {
  return <div className='d-flex flex-grow-1 position-relative page-container'>{children}</div>;
};

export default PageContainer;
