import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faHospital, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import {
  faVialCircleCheck,
  faReceipt,
  faPlus,
  faDna,
  faListOl,
  faCircleArrowDown,
  fas,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';

import { ToastProvider } from '@contexts/useToast';
import { AxiosInterceptor } from '@hooks/useAxios';
import RouterProvider from '@router/routesProvider';
import Store from '@store/store';

import './App.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

library.add(
  faUser,
  faHospital,
  faVialCircleCheck,
  faReceipt,
  faPlus,
  faDna,
  faListOl,
  faSquareCheck,
  faCircleArrowDown,
  fas,
);

function App() {
  return (
    <div className='d-flex flex-column App'>
      <Store>
        <ToastProvider>
          <AxiosInterceptor>
            <RouterProvider />
          </AxiosInterceptor>
        </ToastProvider>
      </Store>
    </div>
  );
}

export default App;
