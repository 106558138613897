import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { orderBy } from 'lodash';

import { IPatientDetailRes } from '@customTypes/patient';
import { Requisition } from '@customTypes/requisition';
import { getPatientName } from '@helpers/patient';
import { isOrderReported } from '@helpers/order';
import { getPatientDetail } from '@store/actions';
import { Context } from '@store/store';

interface IUsePatientDetail {
  requisitions: Requisition[];
  firstCompletedRequisition?: Requisition;
  patientDetailInfo: IPatientDetailRes | null;
  patientName: string;
  loading: boolean;
}

export default function usePatientDetail(patientId?: string): IUsePatientDetail {
  const [state, dispatch] = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [requisitions, setRequisitions] = useState<Requisition[]>([]);
  const [firstCompletedRequisition, setFirstCompletedRequisition] = useState<Requisition | undefined>(undefined);
  const patientDetailInfo = useMemo(
    () => (!patientId ? null : state.patientsMap?.[patientId]),
    [patientId, state.patientsMap],
  );
  const patientName = useMemo(() => getPatientName(patientDetailInfo?.name), [patientDetailInfo]);

  const loadData = useCallback(async () => {
    if (patientId) {
      try {
        const res = await getPatientDetail(patientId);
        dispatch({ type: 'SET_PATIENT_DETAIL', payload: { patientId, result: res } });
      } catch (e) {
        console.error('Error on patient detail api: ', e);
      } finally {
        setLoading(false);
      }
    }
  }, [patientId, dispatch]);

  useEffect(() => {
    const requisitions = state.requisitions?.filter(item => item.individual_id === patientId) ?? [];
    const completedRequisitions = requisitions
      .filter(req => req.orders.every(isOrderReported))
      .map(req => ({
        ...req,
        completedAt: orderBy(req.orders, ['created_at'], ['desc'])[0].created_at,
      }));

    setFirstCompletedRequisition(orderBy(completedRequisitions, ['completedAt'], ['asc'])?.[0]);

    setRequisitions(requisitions);
  }, [patientId, state.requisitions]);

  useEffect(() => {
    if (!patientDetailInfo) {
      loadData();
    }
  }, [loadData, patientDetailInfo]);

  return {
    requisitions,
    firstCompletedRequisition,
    patientDetailInfo,
    patientName,
    loading,
  };
}
