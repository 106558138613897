import dayjs from 'dayjs';

export const dateColumnComparator = (filterDate: Date, cellValue: string) => {
  if (!cellValue) return -1;

  const filterDateString = dayjs(filterDate).format('YYYY-MM-DD');
  const cellValueString = dayjs.utc(cellValue).format('YYYY-MM-DD');

  if (filterDateString === cellValueString) {
    return 0;
  } else if (filterDateString > cellValueString) {
    return -1;
  } else {
    return 1;
  }
};
