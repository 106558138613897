import { useMemo, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { Button, Dropdown, Overlay, ThemeProvider } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFileArrowDown } from '@fortawesome/pro-light-svg-icons';

import { DATE_FORMAT } from '@constants/constants';
import { Requisition } from '@customTypes/requisition';
import { isOrderReported } from '@helpers/order';
import { getPatientOrderTestName } from '@helpers/patient';
import { useDownload } from '@hooks/useDownload';
import useEffectExceptMount from '@hooks/useEffectExceptMount';

import './RequisitionDownloadMenu.scss';

interface IRequisitionDownloadMenuProps {
  requisition: Requisition;
}

export default function RequisitionDownloadMenu({ requisition }: IRequisitionDownloadMenuProps) {
  const { onDownload, onClinicalSummaryLetterDownload } = useDownload();

  const downloadMenuRef = useRef(null);
  const firstItemRef = useRef<HTMLButtonElement | null>(null);
  const [show, setShow] = useState(false);
  const buttonId = useMemo(() => 'menu_' + requisition.requisition_id, [requisition.requisition_id]);
  const downloadMenuListId = `ul_${buttonId}`;

  const clinicalSummaryCompletedAt = useMemo(
    () => dayjs.utc(requisition.clinical_summary_letter?.completed_on).format(DATE_FORMAT),
    [requisition.clinical_summary_letter?.completed_on],
  );

  const orders = useMemo(
    () =>
      requisition.orders
        .filter(order => isOrderReported(order))
        .map(order => ({
          ...order,
          reportTestNames: getPatientOrderTestName(order.product_name),
          formattedReportDate: dayjs.utc(order.reported_at).format(DATE_FORMAT),
        })),
    [requisition.orders],
  );
  const hasDownloads = useMemo(
    () => !!orders.length || !!requisition.clinical_summary_letter,
    [orders.length, requisition.clinical_summary_letter],
  );

  useEffectExceptMount(() => {
    if (show) {
      setTimeout(() => firstItemRef.current?.focus(), 100);
    } else {
      // @ts-ignore
      downloadMenuRef.current?.querySelector('button')?.focus();
    }
  }, [show]);

  return (
    <div className='document-download-menu-wrapper' ref={downloadMenuRef}>
      {/* ThemeProvider wipes bs- prefix to customize component without needing to overwrite Bootstrap styles */}
      <ThemeProvider prefixes={{ btn: 'ag-btn' }}>
        {hasDownloads && (
          <Button
            id={buttonId}
            className={show ? 'document-download-menu-toggle show' : 'document-download-menu-toggle'}
            onClick={() => setShow(true)}
            aria-label={`Download menu for order ${requisition.requisition_id}`}
            aria-controls={downloadMenuListId}
            aria-haspopup='true'
          >
            <FontAwesomeIcon icon={faFileArrowDown as IconDefinition} fontSize={16} />
          </Button>
        )}
        {!hasDownloads && <span>&mdash;</span>}
      </ThemeProvider>
      <Overlay
        target={downloadMenuRef.current}
        show={show}
        placement='right-start'
        onHide={() => setShow(false)}
        rootClose
      >
        <ul
          id={downloadMenuListId}
          className='document-download-menu dropdown-menu'
          aria-labelledby={buttonId}
          tabIndex={0}
        >
          {orders.length > 0 && (
            <li>
              <Dropdown.ItemText as='h5' className='mb-0'>
                <div className='bg-tertiary width-100 text-center text-second fs-xs'>Reports</div>
              </Dropdown.ItemText>
            </li>
          )}
          {orders.map((order, index) => (
            <li key={'li_report_' + index} className='document-download-li'>
              <Dropdown.Item
                as='button'
                ref={index === 0 ? firstItemRef : null}
                onClick={() => {
                  onDownload(order, requisition.requisition_id);
                  setShow(false);
                }}
                aria-label='Download'
              >
                {order.reportTestNames.length > 0 && (
                  <span className='d-block fs-xs fw-medium text-second'>{order.reportTestNames[0]}</span>
                )}
                {order.reportTestNames.length > 1 && (
                  <span className='d-block fs-sm lh-sm'>{order.reportTestNames[1]}</span>
                )}
                {order.reportTestNames.length > 2 && <span className='d-block fs-xs'>{order.reportTestNames[2]}</span>}
                <time dateTime={order.reported_at} className='d-block fs-xs text-second mt-1'>
                  {order.formattedReportDate}
                </time>
              </Dropdown.Item>
            </li>
          ))}
          {!!requisition.clinical_summary_letter && (
            <>
              <li>
                <Dropdown.ItemText as='h5' className='mb-0'>
                  <div className='bg-tertiary width-100 text-center text-second fs-xs'>Other Documents</div>
                </Dropdown.ItemText>
              </li>
              <li className='document-download-li'>
                <Dropdown.Item
                  as='button'
                  onClick={() => {
                    onClinicalSummaryLetterDownload(requisition);
                    setShow(false);
                  }}
                  aria-label='Download'
                >
                  <span className='d-block fs-sm lh-sm'>Clinical Summary Letter</span>
                  <time
                    dateTime={requisition.clinical_summary_letter.completed_on}
                    className='d-block fs-xs text-second mt-1'
                  >
                    {clinicalSummaryCompletedAt}
                  </time>
                </Dropdown.Item>
              </li>
            </>
          )}
        </ul>
      </Overlay>
    </div>
  );
}
