import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { MYOME_HOMEPAGE } from '@constants/constants';

import './AccountReview.scss';

export default function AccountReview() {
  return (
    <div className='my-4 d-flex flex-column rounded-1 bg-white box-shadow-50 gap-3 account-review-wrapper'>
      <FontAwesomeIcon icon={faCircleCheck} className='primary' fontSize={40} />
      <h1 className='fw-semibold mb-0'>Welcome to MyOme!</h1>
      <p className='fw-normal mb-0'>
        We will review your account request. You will receive an email once the account(s) are ready to use.
      </p>
      <Button as='a' className='align-self-center mt-md-2' href={MYOME_HOMEPAGE}>
        MyOme.com
      </Button>
    </div>
  );
}
