import { Button, Modal } from 'react-bootstrap';

import RequisitionReceiptContent from '@components/Requisition/RequisitionReceiptContent/RequisitionReceiptContent';
import { Requisition } from '@customTypes/requisition';
import { useRequisition } from '@hooks/useRequisition';

interface IProps {
  requisition: Requisition;
  onClose: () => void;
}

export default function OrderReceiptDialog({ requisition, onClose }: IProps) {
  const { requisitionId } = useRequisition(requisition);

  return (
    <Modal
      show
      onHide={onClose}
      size='lg'
      id='order-receipt-dialog'
      aria-labelledby={`order_receipt_${requisitionId}`}
      data-testid='orderReceiptDialog'
    >
      <Modal.Header closeButton>
        <Modal.Title as='h4' id={`order_receipt_${requisitionId}`} className='fs-5 fw-medium lh-sm'>
          Order Receipt
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex justify-content-center'>
        <RequisitionReceiptContent requisition={requisition} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose} data-testid='closeOrderReceiptDialog'>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
