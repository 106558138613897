import PartnerBanner from '@components/RequisitionCreate/PartnerBanner/PartnerBanner';

interface IProps {
  children: React.ReactNode;
}

export default function RequisitionCreatePageContainer({ children }: IProps) {
  return (
    <div className='d-flex flex-column flex-grow-1'>
      <PartnerBanner />
      {children}
    </div>
  );
}
